import mapData from 'src/utils/mapper';

/* view_item_list  Ga4*/
function GtagViewItemListGa4(data) {
  if (data && data.gtag) {
    const params = {
      items: prepareEventData(data.gtag, data?.admitad?.categoryId || item?.list_name)
    }

    gtag('event','view_item_list', params);
  }
}

/* select_item Ga4 */
function GtagProductClickGa4(data) {
  let product = {};
  if (Array.isArray(data)) {
    product = {
      ...data[1],
      position: data[0],
      idForAnalytics: data[1].products.id,
    }
  } else {
    product = {
      ...data,
      idForAnalytics: data.idForAnalytics[0],
    };
  }

  const params = {
    items: [{
      item_name: product.name,
      item_id: product.id,
      item_brand: product.brand,
      item_category: product.category,
      item_variant: product.variant ? product.variant: '',
      index: product.position,
      quantity: 1,
      price: product.price,
      idForAnalytics: product.idForAnalytics,
    }]
  }
  gtag('event','select_item', params);
}

/* view_item GA4 */
function GtagProductDetailGa4(data) {
  if (data) {
    const params = {
      items: prepareEventItem(data)
    }

    gtag('event','view_item', params);
  }
}

/* add_to_cart & remove_from_cart  GA4 */
function GtagCartItemGa4(data) {
  if (data && data.product) {
    const params = {
      items: prepareEventItem(data.product)
    }
    gtag('event', data.isAdd ? 'add_to_cart' : 'remove_from_cart', params);
  }
}

/* begin_checkout GA4 */
function GtagBeginCheckoutGa4(data) {
  if (data.items.length) {
    const params = {
      items: prepareEventData(data.items)
    }

    gtag('event', 'begin_checkout', params);
  }
}

/* purchase GA4 */
function GtagPurchaseGa4(data) {
  if (data.google) {
    const params = {
      transaction_id: data.google.id,
      value: data.google.revenue,
      shipping: data.google.shipping,
      currency: "RUB",
      items: prepareEventData(data.google.items)
    }

    gtag('event', 'purchase', params);
  }
}

/* view_promotion GA4 */
function GtagViewPromotionGa4(data) {
  if (data.slider?.length) {
    const banners = data.slider;

    const resultBanners = [];

    Object.keys(banners).forEach(item => {
      const { id, name, position } = banners[item];
      resultBanners.push({
        id,
        name,
        position
      })
    })

    const params = {
      items: resultBanners.map(item => mapData(item, {
          promotion_id: 'id',
          promotion_name: 'name',
        }))
    }

    gtag('event', 'view_promotion', params);
  }
}

/* select_promotion GA4 */
function GtagSelectPromotionGa4(data) {
  const params = {
    items: [{
      promotion_id: data.id,
      promotion_name: data.name,
    }]
  }
  gtag('event', 'select_promotion', params);
}

function prepareEventData (data, list_name) {
  return data.map(item => {
    return {
      item_name: item.name,
      item_id: item.id,
      price: item.price,
      currency: item.currency || '',
      item_brand: item.brand || '',
      item_category: item.category || '',
      item_variant: item.variant || '',
      item_list_name: list_name || '',
      index: item.index || '',
      quantity: item.quantity || 1,
      idForAnalytics: item.idForAnalytics[0],
    }
  })
}

function prepareEventItem (data) {
  return [{
    item_name: data.name,
    item_id: data.id,
    price: data.price,
    currency: data.currency || '',
    item_brand: data.brand,
    item_category: data.category,
    item_variant: data.variant || '',
    item_list_name: data.listName || '',
    quantity: data.quantity,
    idForAnalytics: data.idForAnalytics[0],
  }]
}

export {
  GtagViewItemListGa4,
  GtagProductClickGa4,
  GtagProductDetailGa4,
  GtagCartItemGa4,
  GtagBeginCheckoutGa4,
  GtagPurchaseGa4,
  GtagViewPromotionGa4,
  GtagSelectPromotionGa4
};